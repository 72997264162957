.contactsContainer{
    /* background-color: red; */
    margin-top: 16vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contactsContainer h2{
   margin: 0;
   padding-bottom: 5vh;
}
.contactsContainer h5{
    padding-bottom: 2vh;
}
.addressContainer{
    background-color: white;
    width:50vw;
    font-size: 1.2vw;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}
.addressContainer h4{
    margin: 0;
    padding-bottom: 3vh;
}
.clickable {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
.dividerDiv{
    border: 0.1px solid grey;
    margin-bottom: 5vh;
}