
.infoContainer1{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.columns{
display: flex;
flex:1;
/* background-color: red; */
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 0 1vw;
}
.infoImage {
    /* width:100vw; */
    max-width: 100%;
    height: auto;
    padding: 1vw;
  }
  .imgColumn{
    width:50%;
  }
  .paraContainer{
    width:50%;
    font-size: 1em;
    font-weight: 400;
  }
  .paraContainer h4{
    font-weight: bold;
    font-size: 2.5em;
    color: blue;
  }
  .infoDivider{
    border: 2px solid #007bff;;
    max-width: 10vw;
    margin-bottom: 2vh;
  }

  .visionMissionContainer {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .vision, .mission {
    margin-bottom: 20px;
  }
  
  .vision h2, .mission h2 {
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .vision p, .mission p {
    font-size: 1.2em;
    font-style: italic;
  }

  .servicesContainer {
    padding: 20px;
    /* background-color: #e0e0e0; */
    border-radius: 8px;
  }
  
  .servicesContainer h2 {
    text-align: center;
    color: #007bff;
    margin-bottom: 20px;
  }
  .service ul li{
    list-style-type: none;
    font-size: 1.1em;
    font-weight: 600;
  }
  .service ul li img{
    width:2.5em;
    margin-right: 1vw;
  }
  .service {
    margin-bottom: 20px;
  }
  
  .service h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .service p {
    margin-left: 20px;
    color: #555;
    font-size: 1.1em;
  }

  .companyContainer {
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .companyContainer h2 {
    text-align: center;
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .companyDescription {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .companyDescription p {
    flex: 2;
  }
  
  .companyImage {
    flex: 1;
    max-width: 80%;
    height: auto;
    border-radius: 8px;
  }
  
  .sectorsList {
    list-style-type: none;
    padding: 0;
  }
  
  .sectorsList li {
    margin-bottom: 10px;
    font-size: 1.1em;
    color: #333;
  }