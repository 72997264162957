/* Base Styles */

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is above other content */
}

.formContain {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 90%; /* Adjusted width for better responsiveness */
  max-width: 500px; /* Maximum width for larger screens */
  overflow-y: auto; /* Enable scrolling if content exceeds the modal height */
  border-radius: 10px;
  padding: 2vh 2vw; /* Adjusted padding */
}

.formContainer1 {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; /* Changed position from fixed to relative */
}

.closeDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1vh;
}

#closeButton {
  cursor: pointer;
}

.contactInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 1vh;
}

.contactInfo input {
  width: 100%; /* Full width on smaller screens */
  height: 6vh;
  margin-bottom: 1vh;
  padding-left: 1vw;
  font-size: 12px;
  border-radius: 8px;
  border: 0.5px solid black;
}

.addressInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1px;
}

.addressInfo input,
.addressInfo textarea {
  width: 100%; /* Full width on smaller screens */
  height: 6vh;
  padding-left: 1vw;
  font-size: 12px;
  border-radius: 8px;
  border: 0.5px solid black;
  margin-bottom: 1vh;
}

.addressInfo textarea {
  height: 10vh;
}

.formContainer1 button {
  margin-top: 1vh;
  width: 100%; /* Full width on smaller screens */
}

/* Media Query for screens up to 480px width */

@media (max-width: 480px) {
  .formContain {
      width: 90%;
      max-width: 90%; /* Adjusted maximum width */
      height: 65vh; /* Adjusted height for smaller screens */
  }

  .contactInfo input,
  .addressInfo input,
  .addressInfo textarea {
      font-size: 14px; /* Increased font size for better readability on smaller screens */
  }

  .formContainer1 button {
      font-size: 14px; /* Increased font size for buttons */
  }
}
