.cartContainer1{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.cardContainerCart{
    width: 70vw; 
    height: 25vh; 
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 20vw;
    padding:0 1vw;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    font-family: arial;

}  
.cartHeading1{
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1vw;
    width: 100%;
}
.infoButtonDiv{
    display: flex;
    /* background-color: red; */
}
.infoButtonDiv button{
    border: none;
    padding: .5vw;
    background-color: red;
    border-radius: 1vw;
    color:white;
    margin-right: 1vw;
}
.basketContainer{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 22vh;
    margin-bottom: 22vh;
}
.cartproductsContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: orange;
}
.cartButton{
    display: flex;
    /* background-color: green; */
    align-items: center;
    justify-content: center;
}

.displayCounter{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    
    width:4vw;
}
.cartButton button{
    width:4vw;
    height:6vh;
    border-radius: 0.3vw;
    font-size: 12px;
    /* margin: 0 2px; */
    background-color: black;
    color: white;
}

@media (max-width:480px){
    .cartContainer1{
        display: flex;
       flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        /* background-color: orange; */
    }
.infoButtonDiv button{
    background-color: black;
    
}
.cardContainerCart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:60%;
    height: 30vh;
    margin:1vw;
    margin-left: 20vw;
    margin-top: 4vh;
}
.priceContainer p{
    font-size: 2vw;
}
.cartButton{  
    /* background-color: green; */
    width: 50vw;
    margin-bottom: .5vh;
}
.cartButton button{
    width:20%;
    height:70%;
    border: none;
    border-radius: 1vw;
}
.displayCounter{
    /* background-color: blue; */
    margin-top: -1vh;
    font-size: 3vw;
}
}